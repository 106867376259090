import React from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { ActivityIndicator } from 'antd-mobile';

export const LoadingIndicatorContainer = styled.div``;

const LoadingIndicator = inject(`uiStore`)(
  observer(props => {
    return (
      <LoadingIndicatorContainer>
        <ActivityIndicator
          toast
          text="加载中..."
          animating={props.uiStore.loading}
        />
      </LoadingIndicatorContainer>
    );
  })
);

export default LoadingIndicator;
