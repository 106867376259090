import React, { Component } from 'react';
import Router from 'next/router';
import HeaderMobile from '../presentational/HeaderMobile';

class HeaderMobileContainer extends Component {
  onSearch = () => {
    if (this.props.isMerchant) {
      Router.push('/searchmiddlepage?cat=merchant');
    } else {
      Router.push('/searchmiddlepage');
    }
  };

  render() {
    return (
      <HeaderMobile
        isMerchant={this.props.isMerchant}
        onSearch={this.onSearch}
      />
    );
  }
}

export default HeaderMobileContainer;
